/* header style */
  @import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
.rcs_top_head_content_right img {
	width: 16px;
}
.cb_header_container.container{
    max-width: 1400px;
    width: 100%;
}
.cb_footer_container.container{
  max-width: 1400px;
  width: 100%;
}
.cb_main_heading{
    text-align: center;
    margin: 0 auto;
}
.cb_main_heading h2{
   font-size: 32px;
   margin-bottom: 15px;
   color: #222;
   font-family: 'Questrial', sans-serif !important;

}
.cb_main_heading img{
margin-bottom: 20px;
}

/* make an appointment section style */
.cd_homer_make_btn_box {
	display: flex;
	justify-content: center;
	gap: 10px;
	align-items: center;
	vertical-align: middle;
}
.cd_btn_rama_color {
	background: #69969c;
	color: #fff;
	padding: 10px;
	border: 2px solid #69969c;
	display: inline-block;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 10px;
	width: 200px;
	margin-right: 10px;
	font-size: 12px;
	transition: all 0.5s ease-out 0s;
	/* word-spacing: .16em !important; */
	/* letter-spacing: .12em !important; */
}
.cd_btn_rama_color:hover {
	background: #fff;
	color: #69969c;
}
.cd_text_and_btn_box h2 {
	font-size: 40px;
	font-weight: 700;
	color: #555;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
  margin-bottom: 10px;
}
.cd_text_and_btn_box p {
	margin-bottom: 30px;
	font-size: 20px;
	/* word-spacing: .16em !important; */
	/* letter-spacing: .12em !important; */
}
.cd_text_and_btn_box {
	padding: 30px 0;
	text-align: center;
}
.cd_SUBtext_and_btn_box{
  text-align: center;
}
.cd_SUBtext_and_btn_box h2{
  font-size: 24px;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
  font-weight: 600;
  color: #333;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.cd_SUBtext_and_btn_box p{
  color: #333;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
}
.cd_SUBtext_and_btn_box p a{
  color: #333;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
}
.cb_ring_home_texxt p{
  color: #333;
  font-family: 'Poppins', sans-serif !important;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
  margin-bottom: 10px;
}
.cb_ring_home_texxt h2 {
	font-family: 'Questrial', sans-serif !important;
	font-size: 38px;
	line-height: 1.2;
	font-weight: 500;
	color: #000;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
}

/********* Our Rewards Programs style **********/
.cd_OurRewardsPrograms_section {
	padding: 60px 0;
}
.cd_OurRewardsPrograms_section .cb_ring_home_texxt p {
	width: 60%;
	margin: 0 auto;
}
/************* shipping style ************/
.cb_shipping_text_img h6{
  font-size: 16px;
  text-transform: uppercase;
  /* word-spacing: .16em !important; */
  /* letter-spacing: .12em !important; */
}
.cb_shipping_text_img p {
	font-size: 14px;
	line-height: 22px;
}
.cb_shipping__home_Section {
	background: #69969c;
	color: #fff;
	padding: 50px 0;
}
.cb_shipping_text_img{
  text-align: center;
}
.cb_shipping_text_img svg {
	font-size: 80px;
	margin-bottom: 10px;
}
.cb_shipping_text_img img {
	width: 50px;
	margin-bottom: 20px;
}
.cb_shipping_text_img.first img {
	transform: scale(1.33);
}


/* Responsive media */
@media(min-width:320px) and (max-width:767px){
  .cb_main_heading h2 {
    font-size: 25px;
  }
  .cd_homer_make_btn_box {
    display: block;
  }
  .cb_ring_home_texxt {
    text-align: center !important;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .cd_btn_rama_color {
    width: 100%;
    font-size: 14px;
  }
  .rcs_testi_item p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .rcs_test_star svg {
    font-size: 18px;
  }
  .slick-slider.rcs_testimonial_inner .rcs_testi_item {
    font-size: 18px !important;
    padding: 15px 20px;
    width: 100%;
  }
  .cb_ring_home_texxt h2 {
    font-size: 25px;
    line-height: 30px;
  }
  /* .cb_ring_home_texxt p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 8px;
  } */
  .cb_ring_home_texxt p {
    font-size: 14px;
    line-height: 25px;
    margin-bottom: 8px;
    /* word-spacing: normal !important; */
    /* letter-spacing: normal !important; */
}
.cd_OurRewardsPrograms_section .cb_ring_home_texxt p {
  width: 100%;
 
}
  .order_2{
    order: 1;
  }
  .order_1{
    order: 2;
  }
  .cb_refer_img_box img {
    margin-bottom: 20px;
  }
  .cd_OurRewardsPrograms_section {
    padding: 30px 0;
  }

  .cd_text_and_btn_box h2 {
    font-size: 25px;
  }
.cd_text_and_btn_box p {
  margin-bottom: 30px;
  font-size: 18px;
  word-spacing: normal !important;
  letter-spacing: normal !important;
}
.cd_SUBtext_and_btn_box p {
  font-size: 14px;
}
}

@media(min-width:768px) and (max-width:1024px){
  .cb_refer_img_box img {
    margin-bottom: 30px;
  }
  .cd_homer_make_btn_box {
    display: block;
    text-align: center;
  }
}

@media(min-width:1200px) and (max-width:1440px){
  .cb_shipping_text_img p {
    font-size: 12px;
    line-height: 18px;
  }
  .cd_text_and_btn_box p {
    margin-bottom: 20px;
    font-size: 16px;
  } 
  .cd_SUBtext_and_btn_box h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .cd_text_and_btn_box h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
  .cd_SUBtext_and_btn_box p {
    font-size: 14px;
    line-height: 23px;
  }
  .cb_ring_home_texxt h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .cb_ring_home_texxt p {
    font-size: 14px;
    line-height: 23px;
  }
  .cb_shipping_text_img h6 {
    font-size: 14px;
  }
  .cb_main_heading h2 {
    font-size: 30px;
  }
  .cb_shipping_text_img svg {
    font-size: 60px;
  }
  .cb_shipping__home_Section {
    padding: 30px 0;
  }
}