.cbr_contact_banner{
    background-image:url('../images/cultbrilliance/contactbanner.png');
    height: 350px;
    background-size: cover;
    width: 100%;
  background-repeat: no-repeat;

}
.cbr_io_icon svg {
    font-size: 35px;
}
.cbr_io_icon {
    display: flex;
    gap: 20px;
}
.cbr_tealgreenco {
	background-color: #7ba4a8;
	color: #fff;
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
	padding: 60px 0;
}
.cbr_social_white  a {
    color: #fff !important ;
    font-size: 17px;
}
.cbr_social_white  a span{
    color: #fff;
}
.cbr_social_white  p {
    color: white;
    font-size: 17px;
}
.cbr_greenarea h2 {
    font-size: 38px;
}
.cbr_call_contact {
    padding-top: 10px;
    margin-bottom: 20px;
}
.cbr_contact_box {
    margin-top: 17px;
}
.rcs_myaccount_section.cb_form_outer_box {
	width: 80%;
	margin: 0 auto;
}
.greybaground_color {
	/* padding-top: 4.5%; */
	/* padding-right: 5%; */
	/* padding-bottom: 7%; */
	/* padding-left: 5%; */
	background-color: #dddede;
	/* height: 800px; */
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}
.rcs .form-control {
    height: 56px;
    border-color: rgb(153, 153, 153);
    border-radius: 1px;
}
.rcs textarea.form-control {
    height: 150px;
}
.rcs h1 {
    color: #7ba4a8;
}

/* ++++++++++Responsive css ++++++++++++++*/


/*\\\\\\\\\\\\\\\\\IPAD VIEW */

@media (min-width:1024px) and (max-width:1199px) {
    .cbr_contact_banner {
        height: 193px;
    }
}


@media (min-width:768px) and (max-width:991px) { 
    .cbr_contact_banner {
        height: 150px;
    }
    
}


@media (min-width:320px) and (max-width:767px) {
    .cb_labd_Section .rcs_myaccount_section {
        text-align: center;
    }
    .cbr_contact_banner{
        background-image:url('../images/cultbrilliance/contact-mob.png');
        height: 250px;
    } 
    .cbr_greenarea h2 {
        font-size: 26px;
    } 
    .cbr_io_icon svg {
        font-size: 25px;
    }   
    .cbr_social_white  a {
        font-size: 14px;

    } 
    .cbr_tealgreenco {
        padding: 60px 20px;
    }
    .cbr_social_white p {
        color: white;
        font-size: 14px;
    }
    .rcs h1 {
        font-size: 30px;
    }
    .rcs_myaccount_section.cb_form_outer_box {
        width: 100%;
    }
}
@media (min-width:768px) and (max-width:1024px) {
    .cbr_greenarea h2 {
        font-size: 30px;
    } 
    .cbr_io_icon svg {
        font-size: 25px;
    }   
    .cbr_social_white  a {
        font-size: 16px;

    } 
    .cbr_tealgreenco {
        padding: 60px 20px;
    }
    .cbr_social_white p {
        color: white;
        font-size: 16px;
    }
    .rcs h1 {
        font-size: 30px;
    }
    .rcs_myaccount_section.cb_form_outer_box {
        width: 100%;
    }
}

@media(min-width:1200px) and (max-width:1440px){
    .cbr_greenarea {
        width: 80%;
        margin: 0 auto;
    }
    .cbr_contact_banner {
        height: 230px;
    }
    .rcs h1 {
        font-size: 28px;
    }
    .cb_form_outer_box .form-control {
        height: 42px !important;
    }
    .cb_shipping_text_img img {
        width: 30px;
        margin-bottom: 15px;
    }
}



