.cbr_at_cultured .container-fluid {
    margin: 0;
    padding: 0;
}
.cbr_importntat h2 {
	word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.cbr_how_content {
	width: 80%;
	margin: 0 auto;
}
/* About page style */
.cbr_oxward p, .cbr_a_diamond_laboratory  p, .cbr_recogniz p {
	word-spacing: .16em !important;
	letter-spacing: .12em !important;
	font-size: 15px;
}
.cbr_clustred_we_strive {
	padding: 15px;
	width: 90%;
	margin: 0 auto;
}



.cbr_abotusbanner {
	background-image:url('../images/cultbrilliance/about.jpg');
	background-repeat: no-repeat;
	height: 455px;
	background-size: cover;
	background-position: 100% 100%;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    position: relative;
}
.cbr_abotusbanner h2 {
    font-size:38px;
    text-align: center;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.cbr_abtsecond_row.row {
    padding-top: 30px;
}
.cbr_our_showrrom {
    padding-top: 34px;
}
.cbr_aboutus_page h2 {
    line-height: 1.2;
    font-weight: 500;
    font-size: 38px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.back_telgreen {
	background: #69969c;
	/* padding-top: 112px; */
	/* padding-bottom: 112px !important; */
	/* padding-left: 8% !important; */
	display: flex;
	justify-content: center;
	vertical-align: middle;
	align-items: center;
}
.cbr_we_striv_text h3 {
	color: #fff;
	font-size: 37px;
	line-height: 1.2;
	font-weight: 500;
	word-spacing: .16em !important;
	letter-spacing: .12em !important;
	text-transform: capitalize;
}
ul.listing {
    padding-top: 10px;
    padding-left: 40px;
    list-style: disc;
    margin-bottom: 30px;
    color:#fff;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 15px;
    line-height: 22px;
}
.cbr_we_striv_text p {
    color: white;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 15px;
}
.cbr_diamond-elite p{
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 15px;
}
.rcs .form-control:focus, .rcs .form-control:active, .rcs .form-control:hover{
    box-shadow: none !important;
    outline: 0 !important;
}
.listing li {
    font-size: 16px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}

.cbr_a_diamond_laboratory h3 {
    font-size: 38px;
    line-height: 1.2;
    font-weight: 500;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    text-transform: capitalize;
}
.cbr_dimfd {
    padding-top: 50px;
}
.cbr_recognized_authenticty .row {
    align-items: center;
    justify-content: center;
}
.cbr_recogniz h2 {
	font-size: 38px;
	line-height: 1.5;
	font-weight: 500;
	word-spacing: .16em !important;
	letter-spacing: .12em !important;
	text-transform: capitalize;
}
.regnoz {
	max-width: 70%;
	margin: 0 auto;
}
.cbr_recognized_authenticty {
    padding-top: 50px;
}
/* section.cbr_at_cultured {
    padding-top: 115px;
} */
.meet_our_team {
	padding: 50px 0;
}
section.cbr_aboutus_page {
    padding-top: 50px;
}
/* REFER A FREIND  */
.cbr_refer_a_friend {
	padding: 100px 0;
}
.cbr_referbanner{
    background-image:url('../images/cultbrilliance/refer1.jpg');
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;

    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
/* .cbr_refer_titel {
    text-align: center;
    padding-top:29%;
} */
.cbr_referbanner:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 41%);
}
.cbr_refer_titel h3 {
    color: white;
    font-size: 37px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.cbr_refer_titel p {
    color: white;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 16px;
    line-height: 25px;
}
.cbr_refereal h5 {
	font-size: 35px;
	line-height: 1.2;
	font-weight: 500;
	word-spacing: .16em !important;
	letter-spacing: .12em !important;
	margin-bottom: 15px;
}
.cbr_refereal p {
    line-height: 25px;
    margin-bottom: 17px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    margin-bottom: 10px;
}

/* the diamond elite */
.cbr_Ddiamond_eliye_banner{
    background-image:url('../images/cultbrilliance/diamond-elite.jpg');
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.cbr_Ddiamond_eliye_banner::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(33, 30, 30, 0.69);
}
.cbr_refer_titel {
	position: relative;
	z-index: 99;
}
.cbr_serve {
    padding-top: 50px;
}
.cbr_diamond-elite h4 {
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    font-weight: 600;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.cbr_diamond-elite h2 {
    font-size: 38px;
    line-height: 1.2;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
ul.listul li {
    list-style-type: disc;
    text-align: left;
    font-size: 15px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 15px;
    line-height: 25px;
}
ul.padd {
    padding-left: 5%;
    margin-bottom: 20px;
}
/*  */
.cbr_how_it_work {
    padding-top: 50px;
    padding-bottom: 50px;
}
.cbr_imp_cb_Coinserge {
    color: #fff;
    background: #69969c;
    padding: 100px 0;
}
.cbr_how_it_work .row {
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #fff; */
}
/* .without-back {
    padding-top: 6% !important;
    padding-right: 4% !important;
    padding-bottom: 7% !important;
    padding-left: 12.5% !important;
} */
.without-back {
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin: 0 auto;
}
ul.listih {
    /* list-style: decimal; */
    margin-bottom: 30px;
}
.listih li {
    font-size: 15px;
    margin-bottom: 11px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    line-height: 25px;
}
.cbr_how_content h3 {
    font-size: 38px;
    line-height: 1.2;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.cbr_how_content p{
    font-size: 15px;
    line-height: 25px;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
}
.how-img {
    margin: 0;
    padding: 0;
}
ul.listih1 {
    /* list-style: disc; */
    margin-bottom: 30px;
}
.listih1 svg {
    font-size: 24px;
}
.cbr_importntat svg {
	color: white;
	font-size: 20px;
	margin-right: 10px;
}
.cbr_importntat a {
    color: white;
    word-spacing: .16em !important;
	letter-spacing: .12em !important;
    font-size: 18px;
    line-height: 30px;
    align-items: center;
}
.cbr_importntat a:hover {
    color: white;
}
.doylost li {
    display: flex;
}

/*\\\\\\\\\\\\\\\\\\\media query\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\*/
/* 110% to 133%  */
@media (min-width:1440px) and (max-width:1900px) {
    .cbr_we_striv_text h3 {
        font-size: 25px;
    }


    .cbr_refer_a_friend {
        padding-top: 50px;
    }
    .cbr_how_it_work {
        padding-top: 0px;
        padding-bottom: 25px;
    }
    .cbr_how_content {
        margin-top: 80px;
    }
    /* .without-back {
        padding-top: 2% !important;
        padding-right: 0% !important;
        padding-bottom: 7% !important;
    } */
}


/* 150% */
@media (min-width:1280px) and (max-width:1439px) {
    .cbr_abotusbanner {
        height: 304px;
    }
    .cbr_aboutus_page h2 {
        padding-top: 15px;
        font-size: 25px;
    }
    .cbr_we_striv_text h3 {
        font-size: 25px;
    }

    .cbr_referbanner {
        height: 352px;
    }
    .cbr_recogniz h2 {
        font-size: 25px;
    }
    .cbr_refer_a_friend {
        padding-top: 63px;
    }
    .listing li {
        font-size: 12px;
    }
    .cbr_we_striv_text p {
        font-size: 12px;
    }
    .cbr_a_diamond_laboratory h3 {
        font-size: 25px;
    }
    section.cbr_aboutus_page {
        padding-top: 30px;
    }
    .cbr_oxward p, .cbr_a_diamond_laboratory p, .cbr_recogniz p {
        font-size: 12px;
    }
    .cbr_friend {
        padding-left: 2%;
    }
    .listih li {
        font-size: 14px;
        margin-bottom: 0;
    }
    .cbr_how_content h3 {
        font-size: 30px;
    }
    section.cbr_at_cultured {
        padding-top: 30px;
    }
    .cbr_recognized_authenticty {
        padding-top: 30px;
    }
}


/*\\\\\\\\\\\\\\\\\IPAD VIEW */
@media (min-width: 992px) and (max-width: 1023px) { 
    .cbr_Ddiamond_eliye_banner {
        height: 268px;
    }
    .rcs_signup_to_input_field .input-group button {
        font-size: 7px;
    }
    .cbr_referbanner {
        height: 270px;
    }
    .cbr_abotusbanner {        
        height: 226px;
    }
    .regnoz {
        max-width: 100%;
        padding-bottom: 25px;
    }
    .cbr_recognized_authenticty .row {
        justify-content: flex-start;
    }
}
@media(max-width:991px){
    .cbr_abotusbanner{
        z-index: 1;
    }
    .cbr_abotusbanner::before {
        position: absolute;
        background-color: #ffffff7a;
        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;
    }
}

@media (min-width:768px) and (max-width:991px) { 
    .cbr_recogniz_img img {
        margin-bottom: 30px;
    }
    .cbr_abotusbanner {
        height: 190px;
    }
    .cbr_referbanner {
        height: 215px;
    }  
    .cbr_refer_a_friend {
        padding: 30px 15px;
    }

    .cbr_Ddiamond_eliye_banner {
        height: 210px;
    }
    .about-section {
        padding: 60px 0;
    }
    section.cbr_at_cultured {
        padding-top: 50px;
    }
    .regnoz {
        max-width: 100%;
        padding-top: 25px;
        padding-bottom: 0;
    }
    .meet_our_team {
        padding-top: 0;
    }
    .back_telgreen {
        padding-top: 27px;
    }
    .without-back {
        max-width: 100%;
        padding-top: 25px;
    }
    .cbr_recogniz h2 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .meet_our_team {
        padding: 0;
    }
    .cbr_a_diamond_laboratory h3 {
        font-size: 30px;
        line-height: 30px;
    }
    .cbr_we_striv_text h3 {
        font-size: 25px;
        line-height: 28px;
    }
    .cbr_aboutus_page h2 {
        line-height: 30px;
        font-size: 30px;
    }
    .cbr_abotusbanner h2 {
        font-size: 32px;
    }
    .cbr_we_striv_text {
        padding: 15px;
    }
}
@media (min-width:576px) and (max-width:761px) { 

}
@media (min-width: 576px){
}
/* \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\mobile samll view\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */

@media (min-width:320px) and (max-width:767px) {
    .cbr_recogniz_img img {
        margin-bottom: 20px;
    }
    .cbr_our_showrrom {
        padding-top: 20px;
    }
    section.cbr_aboutus_page {
        padding-top: 30px;
    }
    .cbr_clustred_we_strive {
        padding: 15px;
    }
    .cbr_abotusbanner {
        height: 100px;
    } 
    .cbr_weareher img {
        margin-bottom: 20px;
    }
    .cbr_abtsecond_row.row {
        padding-top: 0;
    }
    .cbr_refer_a_friend {
        padding: 20px 0;
    }
    .cbr_refereal p {
        line-height: 22px;
        margin-bottom: 10px;
        font-size: 14px;
        text-align: center;
    }
    /*refer a friend  */
    .cbr_referbanner{
        background-image:url('../images/cultbrilliance/refer1-mob.jpg');
        height: 250px;
    }
    .cbr_refer_a_friend {
        padding-top: 35px;
    }
    /* .cbr_refer_a_friend {
        padding-top: 39px;
    } */
    .cbr_refereal h5 {
        font-size: 26px;
        text-align: center;
    }
    .cbr_Ddiamond_eliye_banner {
        background-image: url('../images/cultbrilliance/diamond-elite-mob.jpg');
        height:250px;
    }
    .cbr_diamond-elite h2 {
        font-size: 26px;
    }
    .cbr_refer_titel h3 {
        font-size: 34px;
    }
    .cbr_how_content {
        margin-top: 25px;
    }
    .cbr_importntat h4 {
        font-size: 15px;
    }
    .listih1 svg {
        width: 14%;
    }
    .doylost li {
        display: flex;
    }
    }

@media(min-width:320px) and (max-width:767px){
    .cbr_refer_titel p {
        font-size: 14px;
        line-height: 22px;
    }
    .cbr_recogniz h2 {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .meet_our_team {
        padding: 20px 0;
    }
    .cbr_a_diamond_laboratory h3 {
        font-size: 30px;
        line-height: 30px;
    }
    .cbr_we_striv_text h3 {
        font-size: 25px;
        line-height: 28px;
    }
    .cbr_aboutus_page h2 {
        line-height: 30px;
        font-size: 30px;
    }
    .cbr_abotusbanner h2 {
        font-size: 32px;
    }
}
@media(min-width:992px) and (max-width:1024px){
    .cbr_clustred_we_strive {
        padding: 0 15px;
    }
    .regnoz {
        max-width: 98%;
    }
    .cbr_abotusbanner {
        height: 240px;
    }
    .cbr_we_striv_text h3 {
        font-size: 25px;
        line-height: 25px;
    }
    .listing li {
        font-size: 14px;
    }
    .cbr_we_striv_text p {
        font-size: 14px;
    }
    .cbr_recognized_authenticty {
        padding-top: 30px;
    }
    .cbr_recogniz h2 {
        font-size: 30px;
        line-height: 30px;
    }
    .cbr_a_diamond_laboratory h3 {
        font-size: 30px;
        line-height: 30px;
    }
}
@media(min-width:1200px) and (max-width:1440px){
    .cbr_abotusbanner {
        height: 300px;
    }
    .cbr_Ddiamond_eliye_banner {
        height: 330px;
    }
    .cbr_refereal p {
        line-height: 23px;
        margin-bottom: 10px;
        font-size: 14px;
    }
    .cbr_refer_titel h3 {
        font-size: 30px;
    }
    .cbr_refer_titel p {
        font-size: 14px;
        line-height: 23px;
    }
    .cbr_diamond-elite h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .cbr_diamond-elite h4 {
        font-size: 15px;
        line-height: 25px;
    }
    .cbr_diamond-elite p {
        font-size: 14px;
    }
    ul.listul li {
        font-size: 14px;
    }
    .cbr_importntat h2 {
        font-size: 28px;
    }
    .cbr_how_content p {
        font-size: 12px;
        line-height: 22px;
    }
    .listih li {
        font-size: 12px;
        margin-bottom: 0;
        line-height: 22px;
    }
    .cbr_how_content h3 {
        font-size: 25px;
    }
    .cbr_refereal h5 {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .cbr_refereal p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
    }
    .cbr_referbanner {
        height: 330px;
      }
    
}
@media(min-width:1441px) and (max-width:1600px){
    .cbr_abotusbanner {
        height: 385px;
    }
}